import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import LoginPage from '../views/auth/LoginPage';
import DashboardPage from '../views/panel/DashboardPage';
import AuthRoute from './AuthRoute';
import PanelRoute from './PanelRoute';
import UserPage from "../views/panel/UserPage";
import UserNewPage from "../views/panel/users/UserNewPage";
import UserEditPage from "../views/panel/users/UserEditPage";
import BankPage from "../views/panel/banks/BankPage";
import BankNewPage from "../views/panel/banks/BankNewPage";
import BankEditPage from "../views/panel/banks/BankEditPage";
import MerchantPage from "../views/panel/merchants/MerchantPage";
import MerchantNewPage from "../views/panel/merchants/MerchantNewPage";
import MerchantEditPage from "../views/panel/merchants/MerchantEditPage";
import CategoryPage from "../views/panel/categories/CategoryPage";
import CategoryNewPage from "../views/panel/categories/CategoryNewPage";
import CategoryEditPage from "../views/panel/categories/CategoryEditPage";
import ProductPage from "../views/panel/products/ProductPage";
import ProductNewPage from "../views/panel/products/ProductNewPage";
import ProductEditPage from "../views/panel/products/ProductEditPage";
import MerchantProductPage from "../views/panel/merchant-products/MerchantProductPage";
import MerchantProdNewPage from "../views/panel/merchant-products/MerchantProductNewPage";
import MerchantProdEditPage from "../views/panel/merchant-products/MerchantProductEditPage";
import TrxPage from "../views/panel/trx/TrxPage";
import DashboardCaPage from "../views/panel/dashbaord-ca/DashboardCaPage";
import TrxCaPage from "../views/panel/trx/TrxCaPage";
import TrxCaDepositPage from "../views/panel/trx/TrxCaDepositPage";
import TrxCaDepositNewPage from "../views/panel/trx/TrxCaDepositNewPage";
import TrxCaDepositDetailPage from "../views/panel/trx/TrxCaDepositDetailPage";
import DepositPage from "../views/panel/deposit/DepositPage";
import DepositDetailPage from "../views/panel/deposit/DepositDetailPage";
import ReconPage from "../views/panel/recon/ReconPage";
import FoundedReconPage from "../views/panel/recon/FoundedReconPage";
import UnFoundedReconPage from "../views/panel/recon/UnFoundedReconPage";
import TrxDetailPage from "../views/panel/trx/TrxDetailPage";
import SyncPage from "../views/panel/recon/SyncPage";

const  MainRoute = () => {
  return(
      <Router>
        <Switch>

          {/* Auth Routes */}
          <AuthRoute path="/login" component={LoginPage} />

          {/* Panel Users */}
          <PanelRoute path="/panel/dashboard-ca" component={DashboardCaPage} />
          <PanelRoute path="/panel/dashboard" component={DashboardPage} />
          <PanelRoute path="/panel/users" component={UserPage} />
          <PanelRoute path="/panel/users-new" component={UserNewPage} />
          <PanelRoute path="/panel/users-edit/:id" component={UserEditPage} />

          {/* Bank */}
          <PanelRoute path="/panel/banks/edit/:id" component={BankEditPage} />
          <PanelRoute path="/panel/banks/new" component={BankNewPage} />
          <PanelRoute path="/panel/banks" component={BankPage} />

          {/* Merchant */}
          <PanelRoute path="/panel/merchants/edit/:id" component={MerchantEditPage} />
          <PanelRoute path="/panel/merchants/new" component={MerchantNewPage} />
          <PanelRoute path="/panel/merchants" component={MerchantPage} />

          {/* Categories */}
          <PanelRoute path="/panel/categories/edit/:id" component={CategoryEditPage} />
          <PanelRoute path="/panel/categories/new" component={CategoryNewPage} />
          <PanelRoute path="/panel/categories" component={CategoryPage} />

          {/* Products */}
          <PanelRoute path="/panel/products/edit/:id" component={ProductEditPage} />
          <PanelRoute path="/panel/products/new" component={ProductNewPage} />
          <PanelRoute path="/panel/products" component={ProductPage} />

          {/* Recon */}
          <PanelRoute path="/panel/sync" component={SyncPage} />
          <PanelRoute path="/panel/recon/found" component={FoundedReconPage} />
          <PanelRoute path="/panel/recon/unfound" component={UnFoundedReconPage} />
          <PanelRoute path="/panel/recon" component={ReconPage} />

          {/* Merchant Product */}
          <PanelRoute path="/panel/merchant-prods/edit/:id" component={MerchantProdEditPage} />
          <PanelRoute path="/panel/merchant-prods/new" component={MerchantProdNewPage} />
          <PanelRoute path="/panel/merchant-prods" component={MerchantProductPage} />

          {/* Trx Hist */}
          <PanelRoute path="/panel/trx-ca-deposit/edit/:id" component={TrxCaDepositDetailPage} />
          {/*<PanelRoute path="/panel/merchant-prods/new" component={MerchantProdNewPage} />*/}
          <PanelRoute path="/panel/trx-ca-deposit/new" component={TrxCaDepositNewPage} />
          <PanelRoute path="/panel/trx-ca" component={TrxCaPage} />
          <PanelRoute path="/panel/trx-ca-deposit" component={TrxCaDepositPage} />
          <PanelRoute path="/panel/trx" component={TrxPage} />
          <PanelRoute path="/panel/trx-detail" component={TrxDetailPage} />


          {/* Deposit Page */}
          <PanelRoute path="/panel/deposit/:id" component={DepositDetailPage} />
          <PanelRoute path="/panel/deposit" component={DepositPage} />

          {/* Manage Trainings */}
          {/*<PanelRoute path="/panel/trainings/new" component={NewTrainingForm} />*/}
          {/*<PanelRoute path="/panel/trainings/review" component={TrainingReview} />*/}
          {/*<PanelRoute path="/panel/trainings/:id" component={TrainingFormPage} />*/}
          {/*<PanelRoute path="/panel/trainings/pilot-info/:id" component={PilotInformationPage} />*/}
          {/*<PanelRoute path="/panel/trainings" component={IndexPage} />*/}

          {/* Manage Users */}
          {/* <PanelRoute path="/panel/users/new" component={NewUser} /> */}
          {/*<PanelRoute path="/panel/users" component={IndexUser} />*/}

          {/* Root */}
          <AuthRoute path="/" component={LoginPage} />
        </Switch>
      </Router>
  )
}

export default MainRoute;
