export const sideMenu = () => {
  return [
    {
      name: "Dashboard",
      icon: "fas fa-tachometer-alt",
      link: "/panel/dashboard",
      icon2: "",
      key: 0
    },
    {
      name: "User Management",
      icon: "fas fa-user",
      link: "/panel/users",
      icon2: "",
      key: 1
    },
    {
      name: "Bank",
      icon: "fas fa-university",
      link: "/panel/banks",
      icon2: "",
      key: 2
    },
    {
      name: "Merchant",
      icon: "fas fa-laptop-house",
      link: "/panel/merchants",
      icon2: "",
      key: 3
    },
    {
      name: "Merchant Product",
      icon: "fas fa-car-battery",
      link: "/panel/merchant-prods",
      icon2: "",
      key: 4
    },
    {
      name: "Trx History",
      icon: "fas fa-history",
      link: "/panel/trx",
      icon2: "",
      key: 5
    },
    {
      name: "Trx History Detail",
      icon: "fas fa-history",
      link: "/panel/trx-detail",
      icon2: "",
      key: 13
    },
    {
      name: "Deposit",
      icon: "fas fa-history",
      link: "/panel/deposit",
      icon2: "",
      key: 6
    },
    {
      name: "Category",
      icon: "fas fa-layer-group",
      link: "/panel/categories",
      icon2: "",
      key: 10
    },
    {
      name: "Product",
      icon: "fas fa-ad",
      link: "/panel/products",
      icon2: "",
      key: 11
    },
    {
      name: "Recon",
      icon: "fas fa-dollar-sign",
      link: "/panel/recon",
      icon2: "",
      key: 12
    },
    {
      name: "Recon Sync",
      icon: "fas fa-dollar-sign",
      link: "/panel/sync",
      icon2: "",
      key: 14
    },
    {
      name: "Founded Recon",
      icon: "fas fa-dollar-sign",
      link: "/panel/recon/found",
      icon2: "",
      key: 15
    },
    {
      name: "UnFounded Recon",
      icon: "fas fa-dollar-sign",
      link: "/panel/recon/unfound",
      icon2: "",
      key: 16
    },
  ]
}


export const sideMenuCA = () => {
  return [
    {
      name: "Dashboard",
      icon: "fas fa-tachometer-alt",
      link: "/panel/dashboard-ca",
      icon2: "",
      key: 0
    },
    {
      name: "Trx History",
      icon: "fas fa-history",
      link: "/panel/trx",
      icon2: "",
      key: 1
    },
    {
      name: "Deposit",
      icon: "fas fa-laptop-house",
      link: "/panel/trx-ca-deposit",
      icon2: "",
      key: 2
    },
    {
      name: "Category",
      icon: "fas fa-layer-group",
      link: "/panel/categories",
      icon2: "",
      key: 10
    },
    {
      name: "Product",
      icon: "fas fa-ad",
      link: "/panel/products",
      icon2: "",
      key: 11
    },
  ]
}

export const roles = () => {
  return [
    {
      'id': 1,
      'name': 'admin',
    },
    {
      'id': 2,
      'name': 'ca',
    },
    {
      'id': 3,
      'name': 'biller',
    },
  ]
}

export const status = () => {
  return [
    {
      'id': 0,
      'name': 'Success',
    },
    {
      'id': 1,
      'name': 'Pending',
    },
    {
      'id': 2,
      'name': 'Failed',
    },
    {
      'id': 3,
      'name': 'Inquiry',
    },
    {
      'id': 4,
      'name': 'Success with settlement',
    },
    {
      'id': 5,
      'name': 'Inquiry callback',
    },
    {
      'id': 10,
      'name': 'CallBack Failed',
    },
    {
      'id': 11,
      'name': 'Biller Failed',
    },
  ]
}

export const statusDeposit = () => {
  return [
    {
      'id': 3,
      'name': 'Inquiry',
    },
    {
      'id': 0,
      'name': 'Success',
    },
    {
      'id': 4,
      'name': 'Reversal',
    },
  ]
}
