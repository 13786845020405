import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {fetchCategory} from "../../../services/category.service";
import {API_DSN} from "../../../services/config";
import Select from "react-select";
import {fetchBanks} from "../../../services/bank.service";
import moment from "moment/moment";
import {fetchFoundRecons} from "../../../services/recon.service";

const FoundedReconPage = () => {
  const userData = useSelector(state => state.user.user);
  const [categories, setCategories] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [page, setPage] = useState(1);
  // const [cat, setCat] = useState({});
  const [bank, setBank] = useState('');
  const [totalPage, setTotalPage] = useState(20);
  const [timeOutTmp, setTimeOutTmp] = useState(0);
  const [fromDate, setFromDate] = useState({
    from_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    to_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  const [reconData, setReconData] = useState([]);
  const [reconDataTmp, setReconDataTmp] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (userData.role.label === 'admin') {
      setIsAdmin(true);
    }
    // reconData();
    categoriesData();
    bankData();
  }, [history, userData, isAdmin]);

  const getReconData = async (bankCode, from_date = null) => {
    setLoading(true);

    let params = fromDate;
    if (from_date) {
      params = {
        from_date: from_date,
        to_date: from_date,
      };
      setFromDate(params)
    } else if (fromDate.from_date.length !== 10) {
      return [];
    }

    let res = [];
    try {
      res = await fetchFoundRecons(bankCode, params);
      // setReconData(res.data.data);
      // setReconDataTmp(res.data.data);
      // setTotalPage(res.data.count);
    } catch (e) {
      alert('Error load data');
      console.log('err', e);
    }
    // fetchRecons(bankCode, fromDate).then(res => {
    //   console.log(res.data);
    //   setReconData(res.data);
    //   setReconDataTmp(res.data);
    //   setTotalPage(res.data.count);
    // }, err => {
    //   alert('Error load data');
    //   console.log('err', err.response);
    // }).finally(() => {
    //   setLoading(false);
    // });
    setLoading(false);
    return res.data.data;
  }
  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const categoriesData = () => {

    fetchCategory().then(res => {
      setCategories(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const bankData = () => {

    fetchBanks().then(res => {
      const filteredBanks = res.data.data.filter(bank => bank.kode === 'PDAMTKR001' || bank.kode === 'PDAMTB001');
      setBanks(filteredBanks);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const handleChange = (event) => {
    const val = event.target.value;
    setFromDate({
      from_date: val,
      to_date: val
    });

    let searchRes = reconDataTmp;

    if (!event.target.value) {
      setPage(1);
      return;
    }

    clearTimeout(timeOutTmp)
    if (!event) return "";

    if (!bank) {
      return;
    }

    if (event.target.value.length !== 10) {
      return;
    }

    const filterTimeout = setTimeout(async () => {
      console.log('====>', val)
      // setFromDate({
      //   from_date: val,
      //   to_date: val
      // });

      searchRes = {};
      if (val.length !== 10) {
        return;
      }
      searchRes = await getReconData(bank, val);

      setTotalPage(searchRes.length || 1);

      const searchRes2 = searchRes.filter(valFilter => {
        // let cat_name = '-';
        // categories.forEach(val2 => {
        //   if (valFilter.category_id === val2.code) {
        //     cat_name = val2.name;
        //   }
        // });
        return true;
        // return cat_name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        //   valFilter.name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        //   valFilter.kode.toLowerCase().indexOf(val.toLowerCase()) > -1
      });

      setReconData(searchRes2 || []);

    }, 1000);

    setTimeOutTmp(filterTimeout);
  }

  const handleChangeCategory = async (event, currentPage = 1) => {
    // let searchProd2 = {
    //
    // };
    // if (event === null) {
    //   setCat({});
    //   if (Object.keys(bank).length > 0) {
    //     searchProd2 = await seacrchProductByParam(bank, currentPage, search);
    //   } else {
    //     searchProd2 = await seacrchProductByParam({}, 1, search);
    //   }
    // } else {
    //   console.log('event next :>> ', event);
    //   const category_id = event.value;
    //   setCat({ category_id });
    //
    //   if (Object.keys(bank).length > 0) {
    //     const brand = bank.brand;
    //     searchProd2 = await seacrchProductByParam({ brand, category_id   }, currentPage, search);
    //   } else {
    //     searchProd2 = await seacrchProductByParam({ category_id }, currentPage, search);
    //   }
    // }
    //
    // setReconData(searchProd2.data.data);
    // setReconDataTmp(searchProd2.data.data);
    // setTotalPage(searchProd2.data.count)
  }

  const handleChangeBank = async (event, currentPage = 1) => {
    let searchProd2 = {};

    if (fromDate.from_date.length !== 10) {
      return;
    }

    if (event === null) {
      setBank({});
      searchProd2 = setReconData([]);
    } else {
      const bank = event.value;
      setBank(bank)
      searchProd2 = await getReconData(bank);
    }

    setReconData(searchProd2);
    setReconDataTmp(searchProd2);
    setTotalPage(searchProd2.length || 1)
  }

  const handleChangePage = async (currentPage = 1) => {
    let searchProd2 = {};
    setPage(currentPage);
    searchProd2 = await getReconData(bank);
    console.log('s :>> ', searchProd2);

    setReconData(searchProd2.data.data);
    setReconDataTmp(searchProd2.data.data);
  }

  const CategoriesOptions = categories.map(value => {
    return { value: value.code, label: value.name, name: 'category_id', key: value._id }
  })
  const CategoriesSelectComponent = () => (
    <Select isClearable options={CategoriesOptions} placeholder='select Category' id="category_id" name='category_id' onChange={handleChangeCategory} required />
  )

  const BanksOptions = banks.map(value => {
    return { value: value.kode, label: value.name, name: 'bank_id', key: value._id }
  })
  const BanksSelectComponent = () => (
      <Select isClearable options={BanksOptions} placeholder='select Bank' id="bank_id" name='bank_id' onChange={handleChangeBank} />
  )

  const exportButton = () => {
    const trxPath = '/recon/export-excel-pdam';
    return (
        <form method="POST" action={`${API_DSN}${trxPath}`}>
          <input type="hidden" name='from_date' value={fromDate.from_date} />
          <input type="hidden" name='to_date' value={fromDate.to_date} />
          <input type="hidden" name='bankCode' value={bank} />
          <button type="submit" className="btn btn-primary btn-block">Export Data Pdam</button>
        </form>
    );
  }

  const exportBankButton = () => {
    const trxPath = '/recon/export-excel-bank';
    return (
        <form method="POST" action={`${API_DSN}${trxPath}`}>
          <input type="hidden" name='from_date' value={fromDate.from_date} />
          <input type="hidden" name='to_date' value={fromDate.to_date} />
          <input type="hidden" name='bankCode' value={bank} />
          <button type="submit" className="btn btn-primary btn-block">Export Data Bank</button>
        </form>
    );
  }
  
  const loopRecon = () => {
    // console.log('reconData', reconData);
    return reconData.map((val, i) => {
      return (
        <tr key={val.printno + '_' + i}>
          <td>{i+1}</td>
          <td>{bank}</td>
          <td>{val.custcode}</td>
          <td>{val.period}</td>
          <td>{val.paydate}</td>
          <td>{val.loket}</td>
          <td>{currencyFormat(val?.total || 0)}</td>
        </tr>
      );
    })
  }

  const loopPagination = () => {
    const pagination = Math.ceil(totalPage / 20);
    const arr = [];
    for (let i = 0; i < pagination; i++) {
      arr.push(i);
    }
    return arr.map((val, i) => {
      return (
          <li className="page-item" key={'pagination_' + i}>
            <span className="page-link" onClick={() => handleChangePage(i + 1)}>{ i + 1 }</span>
          </li>
      );
    });
  }

  return (
    <div className="row">
      <div className="col-sm-4 mb-3">
        {CategoriesSelectComponent()}
      </div>
      <div className="col-sm-4 mb-3">
        {BanksSelectComponent()}
      </div>
      <div className="col-sm-4 mb-3">
        <input
          type="text"
          name="table_search"
          className="form-control p-1"
          onChange={handleChange}
          value={fromDate.from_date}
          placeholder="Select Date" />
      </div>

      <div className="col-sm-4">
        {exportButton()}
      </div>
      <div className="col-sm-4">
        {exportBankButton()}
      </div>
      <div className="col-12">
        <hr className='hr-user' />
      </div>
      <div className="col-12">
        <div className="card-body table-responsive p-0">
          <table className="table text-nowrap table-striped table-sm">
            <thead>
              <tr >
                <th>No</th>
                <th>PDAM</th>
                <th>Bill ID</th>
                <th>Period</th>
                <th>Date</th>
                <th>Locket</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {loading && <tr><td colSpan='6'>Load Data</td></tr>}
              {(!loading && reconData.length > 0) && loopRecon()}
            </tbody>
          </table>

          <nav aria-label="Page navigation example" >
            <ul className="pagination">
              <li className="page-item">
                <span className="page-link" onClick={() => page === 1 ? '' : handleChangePage(page - 1)}>Previous</span>
              </li>
              {(!loading && reconData.length > 0) && loopPagination()}
              <li className="page-item">
                <span className="page-link" onClick={() => handleChangePage(page + 1)}>Next</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

let pageSet = {
  title: "Recon Found",
  location: '/panel/recon/found'
}

export default withHeader(pageSet)(FoundedReconPage)
